import moment from "moment";

export function convertNumberToDateString(number: number) {
  return moment(number).format("DD-MMM-YYYY HH:mm");
}
export function utcToGtm(date: string) {
  return moment.utc(date).local().format('HH:mm DD-MM-YYYY')
}

export const ConverCurrency = (balance: number) => {
  return balance.toLocaleString("en-US");
};
export const ConvertDateToInt = (dateString: string) => {
  const dateObject = new Date(dateString);
  return dateObject.getTime();
};
export const ConvertDateTimeToInt = (dateString: string) => {
  const dateObject = new Date(dateString);
  return dateObject.getTime();
};

export const convertToTime = (time: any) => {
  return moment(time).format("L");
};

export function convertIntToYYYYMMDD_HHmm(int: number) {
  return {
    date: moment(int).format("YYYY-MM-DD").toString(),
    time: moment(int).format("HH:mm").toString(),
  };
}

function formatDate(date: Date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = date.getMonth();
  const month = months[monthIndex];

  const day = date.getDate();

  const year = date.getFullYear();

  const formattedDate = month + " " + day + ", " + year;
  return formattedDate;
}
function formatMonthYear(date: Date) {
  console.log("date:", date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = date.getMonth();
  const month = months[monthIndex];

  const year = date.getFullYear();

  const formattedDate = month + " " + year;
  return formattedDate;
}

export function convertIntToMMDDYYYY_HHmm(int: number) {
  return {
    date: formatDate(moment(int).toDate()),
    time: moment(int).format("HH:mm").toString(),
  };
}

export function convertDateToYYYYMMDD_HHmm(date: Date | undefined) {
  if (!date) {
    return 'Missing date.';
  }
  return moment(date).format("YYYY-MM-DD HH:mm");
}
export function convertStringToYYYYMMDD(date: string) {
  return moment(date, "HH:mm:ss DD-MM-YYYY").format("YYYY-MM-DD HH:mm");
}
export function convertIntToYYYYMMDD(intTime: number) {
  return moment(intTime * 1000).format("YYYY-MM-DD HH:mm");
}
export function convertIntToMMDDYYYY(intTime: number) {
  return moment(intTime * 1000).format("MM/DD/YYYY HH:mm");
}

export function subtractDate(start: number, end: number) {
  const startDate = moment(start);
  const endDate = moment(end);
  const duration = moment.duration(endDate.diff(startDate));
  return {
    date: Math.ceil(duration.asDays()),
    hours: Math.ceil(duration.asHours()),
  };
}
export function getCurrentDateByInt() {
  return new Date().getTime();
}

export function createMonthYearList(createTime: Date) {
  const currentDate = new Date(); // Get the current date

  // Extract the month and year from the create time
  const createMonth = createTime.getMonth();
  const createYear = createTime.getFullYear();

  // Calculate the number of months between the create time and the current time
  const numMonths =
    (currentDate.getFullYear() - createYear) * 12 +
    (currentDate.getMonth() - createMonth) +
    1;

  // Generate the list of month/year values
  const monthYearList = [];

  for (let i = 0; i < numMonths; i++) {
    let month = createMonth + i;
    const year = createYear + Math.floor(month / 12);
    month = month % 12;
    const formattedMonthYear = ("0" + (month + 1)).slice(-2) + "/" + year;

    monthYearList.push(formattedMonthYear);
  }

  return monthYearList;
}

export function getCurrentMonthYear() {
  const currentDate = new Date(); // Get the current date

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  return currentMonth + "/" + currentYear;
}

export function getIntFromDateMMYYYY(value: string) {
  const date = value.split("/");
  const month = +date[0];
  const year = +date[1];
  return new Date(year, month - 1, 15).getTime();
}

export function convertDateToMMYYYY(date = new Date().toString()) {
  const createdDate = moment(
    "11:40:26 15/06/2023",
    "HH:mm:ss DD/MM/YYYY"
  ).format("YYYY-MM-DD[T]HH:mm:ss");

  const newDate = moment(createdDate).toDate();

  return formatMonthYear(newDate);
}
