export enum SearchParamsKey {
  FormMode = 'formMode',
  MaterialType = 'materialType',
}

export enum FormMode {
  Create = "CREATE",
  Edit = "EDIT",
  View = "VIEW",
}
export enum MaterialType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  OTHER = 'OTHER',
}
export enum CHANNELS_CODE {
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  Twitter = "TWITTER",
  Tiktok = "TIKTOK",
  Youtube = "YOUTUBE",
  Others = "OTHERS",
}

export enum AdminRole {
  SuperAdmin = "SUPPER_ADMIN",
  Admin = "ADMIN",
  MarketingAgent = "MARKETING_AGENT",
}

export enum ApplicationSettingTypes {
  Number = "Number",
  Text = "Text",
  Boolean = "Boolean",
}

export enum PopupScreens {
  ScreenHome = "SCREEN_HOME",
  ScreenText = "SCREEN_TEXT",
  ScreenImage = "SCREEN_IMAGE",
  ScreenPdf = "SCREEN_PDF",
  ScreenDialog = "SCREEN_DIALOG",
}

export enum PopupIconType {
  Info = "INFO",
  Warning = "WARNING",
}

export enum PopupType {
  Custom = "CUSTOM",
  Default = "DEFAULT",
  Image = "IMAGE",
}

export enum PushNotiType {
  Now = "NOW",
  Schedule = "SCHEDULE",
}

export enum LangType {
  English = "en",
  Korea = "ko",
  vietnamese = "vi",
  Japan = "jp",
  China = "cn",
  Spanish = "es",
  Portuguese = "pt",
}

export enum UserType {
  Trial = "TRIAL",
  Active = "ACTIVE",
  Premium = "PREMIUM",
}

export enum AgentStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Block = "BLOCK",
}

export enum UserAgentIdentifyStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Submitted = "SUBMITTED",
}

export enum NewfeedStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Block = "BLOCK",
}

export enum NewfeedCommentStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Block = "BLOCK",
}

export enum UserType_Subscriber {
  User = "USER",
  Agent = "AGENT",
}
