import { IRowNode } from "ag-grid-community";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Agent, AgentPending } from "../../../constants/interface";
import Api from "../../../service";
import { toast } from "react-toastify";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface AgentContextType {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isOpenDetailModal: boolean;
  setIsOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>;

  agent: Agent | null;
  setAgent: React.Dispatch<React.SetStateAction<Agent | null>>;

  agentPending: AgentPending | null;
  setAgentPending: React.Dispatch<React.SetStateAction<AgentPending | null>>;

  isReloadList: boolean;
  setIsReloadList: React.Dispatch<React.SetStateAction<boolean>>;

  node: any;
  setNode: React.Dispatch<any>;
}

// Create the context
const AgentContext = createContext<AgentContextType>({
  /*eslint-disable */
  isOpenModal: false,
  setIsOpenModal: () => {},
  isOpenDetailModal: false,
  setIsOpenDetailModal: () => {},
  agent: null,
  setAgent: () => {},
  agentPending: null,
  setAgentPending: () => {},
  isReloadList: false,
  setIsReloadList: () => {},
  node: "",
  setNode: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const AgentContextProvider = (props: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);
  const [isReloadList, setIsReloadList] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent | null>(null);
  const [agentPending, setAgentPending] = useState<AgentPending | null>(null);
  const [node, setNode] = useState<any | null>(null);

  return (
    <AgentContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isOpenDetailModal,
        setIsOpenDetailModal,
        agent,
        setAgent,
        agentPending,
        setAgentPending,
        isReloadList,
        setIsReloadList,
        node,
        setNode,
      }}
    >
      {props.children}
    </AgentContext.Provider>
  );
};

export const AgentOutlet = (props: any) => {
  return (
    <AgentContextProvider>
      {props.children ? props.children : <Outlet />}
    </AgentContextProvider>
  );
};
export default AgentContext;
