import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Icon, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Api from "../../../service";
import MediaContext from "../context/media-context";
import { LangType } from "../../../constants/types";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "react-quill/dist/quill.snow.css";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CustomTabComponent from "./CustomTab";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const CreateButton = styled(LoadingButton)`
  padding: 8px 32px !important;
  background: rgb(85, 177, 71) !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  cursor: pointer;
`;
const ButtonText = styled.span`
  font-size: 20px;
`;

const FormWrap = styled.div`
  overflow: hidden auto;
  height: 100vh;
`;

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  min-height: 400px;
  background: #fff;
  border-radius: 24px;
  width: 70%;
  margin: 5% auto;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const AndroidIOSWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
`;

const ColumnWrap = styled.div<{ widthPercent?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : "100%")};
  gap: 16px;
  .MuiFormHelperText-root {
    color: #d32f2f;
  }
`;

const TitleWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.0025em;
  text-transform: uppercase;
  color: #222222;
`;

const RequiredStart = styled.span`
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
  color: red;
`;

const ErrorText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: rgb(211, 47, 47);
`;

const UploadWrap = styled.div`
  cursor: pointer;
  width: 200px;
  height: 300px;
  border-radius: 8px;
  background-color: "rgba(0,0,0,.02)";
  border: 1px dashed #d9d9d9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const UploadText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

const UploadTextNote = styled(UploadText)`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #cccccc;
`;

const CameraBtn = styled(CloudUploadIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const FieldTitle = styled.span`
  min-width: 200px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.0025em;
  color: #222222;
`;

const PhotoWrap = styled.div`
  max-width: 300px;
  height: 200px;
  max-height: 200px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const CloseWrap = styled.div<{ isCloseLang?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ isCloseLang }) => (isCloseLang ? "0" : "0 24px")};

  ${({ isCloseLang }) =>
    isCloseLang && {
      position: "absolute",
      right: "0",
      top: "0",
    }};
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const CloseBtn = styled(CloseIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

interface IPhoto {
  urlPreview?: string;
  formDataValue: string;
}

interface ILang {
  lang: string;
  title: string;
  description: string;
  body: string;
}

interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CreateFormModal({ isOpen, setIsOpen }: Iprops) {
  const mediaCtx = useContext(MediaContext);
  const { media, setMedia, isReloadList, setIsReloadList } = mediaCtx;

  const [tab, setTab] = useState<string>(LangType.English);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckerror, setIsCheckerror] = useState(false);
  const [show, setShow] = useState<boolean>(true);

  const [langEN, setLangEN] = useState<ILang>({
    lang: LangType.English,
    title: "",
    description: "",
    body: "",
  });
  const [langKO, setLangKO] = useState<ILang>({
    lang: LangType.Korea,
    title: "",
    description: "",
    body: "",
  });
  const [langVN, setLangVN] = useState<ILang>({
    lang: LangType.vietnamese,
    title: "",
    description: "",
    body: "",
  });
  const [langJP, setLangJP] = useState<ILang>({
    lang: LangType.Japan,
    title: "",
    description: "",
    body: "",
  });
  const [langCN, setLangCN] = useState<ILang>({
    lang: LangType.China,
    title: "",
    description: "",
    body: "",
  });
  const [langES, setLangES] = useState<ILang>({
    lang: LangType.Spanish,
    title: "",
    description: "",
    body: "",
  });
  const [langPT, setLangPT] = useState<ILang>({
    lang: LangType.Portuguese,
    title: "",
    description: "",
    body: "",
  });

  const [photoSelected, setPhotoSelected] = useState<IPhoto>({
    urlPreview: "",
    formDataValue: "",
  });

  const handleClose = () => {
    setIsOpen(false);
    media?._id && clearMediaSelected();
  };

  const handleUploadImages = async (file: any) => {
    if (!file) return "";
    let formData = new FormData();
    formData.append("image", file);
    const formDataArray = Array.from(formData.entries());
    console.log(formDataArray);
    try {
      const responsive = await Api.Media.uploadImage(formData);
      return responsive.data;
    } catch (error) {
      console.log("Upload file error:", error);
    }
  };

  const handleValidateForm = () => {
    const invalid =
      !photoSelected.urlPreview ||
      !langEN.title ||
      !langEN.description ||
      !langEN.body ||
      langEN.body === "<p><br></p>";

    return invalid;
  };

  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setPhotoSelected({
        urlPreview: e.target?.result?.toString() || "",
        formDataValue: file || "",
      });
    };
  };

  const handleSubmitForm = async (e: any) => {
    console.log("handleSubmitForm:");
    e.preventDefault();

    setIsCheckerror(true);

    if (handleValidateForm()) return;

    try {
      setIsLoading(true);

      const thumbnail = photoSelected.formDataValue
        ? await handleUploadImages(photoSelected.formDataValue)
        : "";

      const mediaToPost = {
        ...(thumbnail && { thumbnail }),
        isShow: show,
        contents: [
          { ...langEN },
          { ...langKO },
          { ...langVN },
          { ...langJP },
          { ...langCN },
          { ...langES },
          { ...langPT },
        ],
        ...(media?._id && { blogId: media?._id }),
      };

      const response = media?._id
        ? await Api.Media.updateMedia(mediaToPost)
        : await Api.Media.addMedia(mediaToPost);
      if (response) {
        toast.success(media?._id ? "Update succes !" : "Create success !");
        setIsReloadList(!isReloadList);
        handleClose();
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearMediaSelected = () => {
    setMedia(null);
  };

  const handleBindingFormData = async () => {
    bindingFormDataSelected();
  };

  const bindingFormDataSelected = () => {
    if (!media?._id) return;

    setPhotoSelected({
      urlPreview: media?.thumbnail || "",
      formDataValue: "",
    });
  };

  useEffect(() => {
    handleBindingFormData();
  }, []);

  const handleChangeTab = (e: any, value: string) => {
    setTab(value);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box component="form">
          <FormWrap>
            <Form>
              <CloseWrap>
                <IconWrap fontSize="large" onClick={handleClose}>
                  <CloseBtn />
                </IconWrap>
              </CloseWrap>
              <TitleWrap>
                <Title>Media</Title>
              </TitleWrap>
              <RowWrap>
                <ColumnWrap>
                  <FieldTitle>
                    Thumbnail{" "}
                    {!photoSelected.urlPreview && (
                      <RequiredStart>*</RequiredStart>
                    )}
                  </FieldTitle>
                  <AndroidIOSWrap style={{ alignItems: "flex-start" }}>
                    <ColumnWrap style={{ maxWidth: 300 }}>
                      <ColumnWrap style={{ maxWidth: 300 }}>
                        <input
                          name="thumbnail"
                          onChange={handleCapture}
                          accept="image/*"
                          id="icon-button-photo-android"
                          //multiple
                          type="file"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="icon-button-photo-android">
                          <UploadWrap>
                            <IconWrap fontSize="large">
                              <CameraBtn />
                            </IconWrap>
                            <UploadText>Select image to upload</UploadText>
                            <UploadTextNote>
                              (ratio: 2x3 - pixel: 800 x 1200)
                            </UploadTextNote>
                          </UploadWrap>
                        </label>
                        {isCheckerror && !photoSelected.urlPreview && (
                          <ErrorText>is required</ErrorText>
                        )}
                      </ColumnWrap>
                    </ColumnWrap>
                    <ColumnWrap widthPercent={50}>
                      {photoSelected.urlPreview && (
                        <AndroidIOSWrap>
                          <PhotoWrap>
                            <Photo
                              src={photoSelected.urlPreview}
                              alt="thumbnail"
                              loading="lazy"
                            />
                          </PhotoWrap>
                        </AndroidIOSWrap>
                      )}
                    </ColumnWrap>
                  </AndroidIOSWrap>
                </ColumnWrap>
              </RowWrap>
              <RowWrap>
                <FieldTitle>Show</FieldTitle>
                <ColumnWrap>
                  <Switch
                    checked={show}
                    onChange={(e: any) => setShow(e?.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </ColumnWrap>
              </RowWrap>
              <RowWrap style={{ alignItems: "flex-start" }}>
                <ColumnWrap>
                  <FieldTitle>Languages</FieldTitle>
                  <CustomTabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                      >
                        <CustomTab label={"English"} value={LangType.English} />
                        <CustomTab label={"Korea"} value={LangType.Korea} />
                        <CustomTab
                          label={"Vietnamese"}
                          value={LangType.vietnamese}
                        />
                        <CustomTab label={"Japanese"} value={LangType.Japan} />
                        <CustomTab label={"Chinese"} value={LangType.China} />
                        <CustomTab label={"Spanish"} value={LangType.Spanish} />
                        <CustomTab
                          label={"Portuguese"}
                          value={LangType.Portuguese}
                        />
                      </TabList>
                    </Box>
                    <CustomTabComponent
                      value={LangType.English}
                      lang={langEN}
                      setLang={setLangEN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      isShowError
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.Korea}
                      lang={langKO}
                      setLang={setLangKO}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.vietnamese}
                      lang={langVN}
                      setLang={setLangVN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.Japan}
                      lang={langJP}
                      setLang={setLangJP}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.China}
                      lang={langCN}
                      setLang={setLangCN}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.Spanish}
                      lang={langES}
                      setLang={setLangES}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                    <CustomTabComponent
                      value={LangType.Portuguese}
                      lang={langPT}
                      setLang={setLangPT}
                      isCheckerror={isCheckerror}
                      setIsCheckerror={setIsCheckerror}
                      setIsLoading={setIsLoading}
                    />
                  </CustomTabContext>
                </ColumnWrap>
              </RowWrap>
              <ButtonWrap>
                <CreateButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleSubmitForm}
                >
                  <ButtonText>{media?._id ? "Update" : "Create"}</ButtonText>
                </CreateButton>
              </ButtonWrap>
            </Form>
          </FormWrap>
        </Box>
      </Modal>
    </>
  );
}

export default CreateFormModal;
