import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AgentContext from "../../../context/agent-context";
import AuthContext from "../../../../../context/auth-context";
import styled from "styled-components";
import ButtonCRUD from "../../../../../components/buttonCRUD";
import { CRUDType } from "../../../../../components/buttonCRUD";
import {
  AgentStatus,
  CHANNELS_CODE,
  UserType,
} from "../../../../../constants/types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, Chip, Modal } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Api from "../../../../../service";
import { toast } from "react-toastify";
import { breakPoints } from "../../../../../constants/device";
import { Agent, Channel } from "../../../../../constants/interface";
import moment from "moment";

const Container = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 50px;
`;

const Row = styled.div<{ marginLeft?: number; isContainer?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 32px;
  ${({ marginLeft }) => marginLeft && `padding-left: ${marginLeft}px`}
  ${({ isContainer }) => isContainer && "gap: 160px; overflow: hidden auto;"}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 10px;
  }
`;

const WrapChannel = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 10px;
  }
`;

const AvatarWrap = styled.div`
  min-width: 200px;
  max-width: 200px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  border-radius: 50%;
`;

const WrapText = styled.div`
  min-width: 250px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 200px;
  }
`;
const WrapInput = styled.div`
  min-width: 400px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 250px;
  }
`;

const WrapInputMutiline = styled.div`
  min-width: 800px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 400px;
  }
`;

const WrapTextRightColumn = styled.div`
  min-width: 300px;
  @media (max-width: ${breakPoints.exDesktop}) {
    min-width: 250px;
  }
`;

const TextStyle = styled.span`
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  /* text-transform: capitalize; */
  color: #222222;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const LinkStyle = styled(TextStyle)`
  text-decoration: underline;
  color: #0000ee;
  cursor: pointer;
  :hover {
    color: #551a8b;
  }
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 16px;
  }
`;

const GroupTitleStyle = styled(TextStyle)`
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  @media (max-width: ${breakPoints.exDesktop}) {
    font-size: 20px;
  }
`;

const FullName = styled(TitleStyle)`
  font-size: 24px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 20px;
  text-transform: none;
  color: gray;
`;

const Wrap = styled.div``;

const WrapButton = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${breakPoints.exDesktop}) {
    gap: 12px;
  }
`;

const CustomButton = styled(LoadingButton)<{ bgColor: string; width?: number }>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 24px !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  /* min-width: 35px !important; */
  min-width: 110px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    object-fit: contain;
    :hover {
      transform: scale(4);
      cursor: zoom-in;
    }
  }
`;
const ModalContainer = styled.div`
  width: 60%;
  /* max-height: 90vh;
  overflow-y: scroll; */
`;
const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export interface AgentUpdate {
  fullname?: string;
  email?: string;
  phone?: string;
  country?: string;
  main_channel?: string;
  communication_channel?: string;
  currently_job?: string;
  sale_experience?: string;
  sales_volume?: string;
  areas_of_bussiness?: string;
  about_yourself?: string;
  address_detail?: string;
  outline_reason?: string;
  as_an_agent?: string;
  what_do_you_think?: string;
  provider?: string;
}

const renderSocialLinkChannel = (channel: Channel) => {
  const channelType = channel.channel;
  switch (channelType) {
    case CHANNELS_CODE.Facebook:
      return `https://www.facebook.com/${channel.link}`;
    case CHANNELS_CODE.Instagram:
      return `https://www.instagram.com/${channel.link}`;
    case CHANNELS_CODE.Tiktok:
      return `https://www.tiktok.com/@${channel.link}`;
    case CHANNELS_CODE.Youtube:
      return `https://www.youtube.com/@${channel.link}`;
    case CHANNELS_CODE.Twitter:
      return `https://twitter.com/${channel.link}`;
    case CHANNELS_CODE.Others:
      return channel.link;
  }
};
function Profile() {
  const authCtx = useContext(AuthContext);
  const userCtx = useContext(AgentContext);
  const { role } = authCtx;
  const { node, agent, setAgent } = userCtx;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [selectedStatus, setSelectedStatus] = useState<string>(
  //   agent?.status?.toUpperCase() || UserType.Trial
  // );
  const [formState, setFormState] = useState<AgentUpdate>({
    fullname: agent?.fullname,
    email: agent?.email,
    phone: agent?.phone,
    country: agent?.country,
    // main_channel: agent?.main_channel,
    // communication_channel: agent?.communication_channel,
    currently_job: agent?.currently_job,
    sale_experience: agent?.sale_experience,
    sales_volume: agent?.sales_volume,
    areas_of_bussiness: agent?.areas_of_bussiness,
    address_detail: agent?.address_detail,
    outline_reason: agent?.outline_reason,
    as_an_agent: agent?.as_an_agent,
    what_do_you_think: agent?.what_do_you_think,
    provider: agent?.provider,
  });

  const [imgPopup, setImgPopup] = useState<string | null>(null);

  // const handleChangeSelect = (event: SelectChangeEvent) => {
  //   setSelectedStatus(event.target.value as string);
  // };

  const handleOpenEdit = () => {
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    handleReLoadFormState();
  };

  const handleChangeInputField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleReLoadFormState = () => {
    setFormState({
      fullname: agent?.fullname,
      email: agent?.email,
      phone: agent?.phone,
      country: agent?.country,
      // main_channel: agent?.main_channel,
      // communication_channel: agent?.communication_channel,
      currently_job: agent?.currently_job,
      sale_experience: agent?.sale_experience,
      sales_volume: agent?.sales_volume,
      areas_of_bussiness: agent?.areas_of_bussiness,
      about_yourself: agent?.about_yourself,
      address_detail: agent?.address_detail,
      outline_reason: agent?.outline_reason,
      as_an_agent: agent?.as_an_agent,
      what_do_you_think: agent?.what_do_you_think,
      provider: agent?.provider,
    });
  };

  const handleUpdateProfile = async () => {
    if (!agent?._id) {
      toast.error("cannot update !");
      return;
    }

    if (
      !formState.email
        ?.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error("Please type correct email");
      return;
    }

    if (!formState.phone?.toLowerCase().match(/([0-9]{8})\b/)) {
      toast.error("Please type correct phone");
      return;
    }

    try {
      setIsLoading(true);
      const dataToPost = {
        agentId: agent?._id,
        phone: formState.phone || "",
        country: formState.country || "",
        address_detail: formState.address_detail || "",
        // main_channel: formState.main_channel || "",
        // communication_channel: formState.communication_channel || "",
        fullname: formState.fullname || "",
        currently_job: formState.currently_job || "",
        sale_experience: formState.sale_experience || "",
        sales_volume: formState.sales_volume || "",
        areas_of_bussiness: formState.areas_of_bussiness || "",
        about_yourself: formState.about_yourself || "",
        outline_reason: formState.outline_reason || "",
        as_an_agent: formState.as_an_agent || "",
        what_do_you_think: formState.what_do_you_think || "",
      };

      const response = await Api.Agent.updateProfile(dataToPost);
      if (response) {
        setAgent(response.data);
        node.setData({
          ...agent,
          phone: response?.data?.phone || "",
          country: response?.data?.country || "",
          address_detail: response?.data?.address_detail || "",
          // main_channel: response?.data?.main_channel || "",
          // communication_channel: response?.data?.communication_channel || "",
          fullname: response?.data?.fullname || "",
          currently_job: response?.data?.currently_job || "",
          sale_experience: response?.data?.sale_experience || "",
          sales_volume: response?.data?.sales_volume || "",
          areas_of_bussiness: response?.data?.areas_of_bussiness || "",
          outline_reason: response?.data?.outline_reason || "",
          as_an_agent: response?.data?.as_an_agent || "",
          what_do_you_think: response?.data?.what_do_you_think || "",
        });
        setIsEdit(false);
        toast.success("Update agent profile success !");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleReLoadFormState();
  }, [JSON.stringify(agent)]);

  return (
    <Container>
      <Wrapper>
        <Row isContainer>
          <Column style={{ minWidth: "30%" }}>
            <GroupTitleStyle>General Information</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Full Name</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.fullname ? agent?.fullname : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      name="fullname"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.fullname}
                      onChange={handleChangeInputField}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Email</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.email ? agent?.email : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      name="email"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      type="email"
                      required
                      value={formState.email}
                      onChange={handleChangeInputField}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Country</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.country ? agent?.country : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      name="country"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.country}
                      onChange={handleChangeInputField}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Phone</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.phone ? agent?.phone : "-"}</TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      name="phone"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.phone}
                      onChange={handleChangeInputField}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Address Detail</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.address_detail ? agent?.address_detail : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInput>
                    <TextField
                      name="address_detail"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.address_detail}
                      onChange={handleChangeInputField}
                      fullWidth
                      id="outlined"
                      size="small"
                    />
                  </WrapInput>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Dob</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.dob ? moment(agent?.dob).format("YYYY-MMM-DD") : "-"}
                </TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Gender</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.gender}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Zip Code</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.zip_code ? agent?.zip_code : "-"}</TextStyle>
              </WrapText>
            </Row>

            <GroupTitleStyle>Identity & Credentials</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Selfie ID front</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_selfie ? (
                    <img
                      src={agent?.image_selfie}
                      alt=""
                      onClick={() => setImgPopup(agent?.image_selfie)}
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>ID Front</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_card_identify_front ? (
                    <img
                      src={agent?.image_card_identify_front}
                      alt=""
                      onClick={() =>
                        setImgPopup(agent?.image_card_identify_front)
                      }
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>ID Back</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <ImageWrapper>
                  {agent?.image_card_identify_back ? (
                    <img
                      src={agent?.image_card_identify_back}
                      alt=""
                      onClick={() =>
                        setImgPopup(agent?.image_card_identify_back)
                      }
                    />
                  ) : (
                    "-"
                  )}
                </ImageWrapper>
              </WrapText>
            </Row>

            <GroupTitleStyle>Sales Experience</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Main Channel</TitleStyle>
              </WrapText>
              <WrapText>
                {Array.isArray(agent?.main_channel) &&
                  agent?.main_channel?.map((item: any) => (
                    <WrapChannel>
                      <TextStyle>
                        {item.channel}
                        {" :"}
                      </TextStyle>
                      {typeof item?.link === "string" &&
                      String(item?.link).startsWith("https://" || "http://") ? (
                        <LinkStyle
                          onClick={() => window.open(item.link, "_blank")}
                        >
                          {item.link}
                        </LinkStyle>
                      ) : (
                        <LinkStyle
                          onClick={() => {
                            window.open(
                              renderSocialLinkChannel(item),
                              "_blank"
                            );
                          }}
                        >
                          {item.link}
                        </LinkStyle>
                      )}
                    </WrapChannel>
                  ))}
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Current Job</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.currently_job !== "others"
                        ? agent?.currently_job
                        : `Others: ${agent?.currently_job_custom}`}

                      {!agent?.currently_job && "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="currently_job"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.currently_job}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Business Area</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.areas_of_bussiness !== "others"
                        ? agent?.areas_of_bussiness
                        : `Others: ${agent?.areas_of_bussiness_custom}`}

                      {!agent?.areas_of_bussiness && "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="areas_of_bussiness"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.areas_of_bussiness}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Sales Experience</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.sale_experience ? agent?.sale_experience : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="sale_experience"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.sale_experience}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Expected Sales Per Week</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.sales_volume ? agent?.sales_volume : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="sales_volume"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.sales_volume}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Tell me more about yourself</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.about_yourself ? agent?.about_yourself : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="about_yourself"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.about_yourself}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>

            {/* <GroupTitleStyle>Further Question</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Why Choose Start24</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.outline_reason ? agent?.outline_reason : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="outline_reason"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.outline_reason}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Most Preferable Features</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.as_an_agent ? agent?.as_an_agent : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="as_an_agent"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.as_an_agent}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row>
            <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Difficulties</TitleStyle>
              </WrapTextRightColumn>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>
                      {agent?.what_do_you_think
                        ? agent?.what_do_you_think
                        : "-"}
                    </TextStyle>
                  </WrapText>
                ) : (
                  <WrapInputMutiline>
                    <TextField
                      name="what_do_you_think"
                      // helperText={
                      //   isCheckerror &&
                      //   !langVN.description &&
                      //   "is required"
                      // }
                      value={formState.what_do_you_think}
                      onChange={handleChangeInputField}
                      fullWidth
                      multiline
                      rows={5}
                      id="outlined"
                      size="small"
                    />
                  </WrapInputMutiline>
                )}
              </Wrap>
            </Row> */}

            <GroupTitleStyle>other features</GroupTitleStyle>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Verified</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.verified ? (
                    <Chip label="True" color="success" variant="outlined" />
                  ) : (
                    <Chip label="False" color="warning" variant="outlined" />
                  )}
                </TextStyle>
              </WrapText>
            </Row>
            {/* <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Updated</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.updated ? (
                    <Chip label="True" color="success" variant="outlined" />
                  ) : (
                    <Chip label="False" color="warning" variant="outlined" />
                  )}
                </TextStyle>
              </WrapText>
            </Row> */}
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Provider</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.provider ? agent?.provider : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Login Type</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>
                  {agent?.googleId ? "Google" : "Email/Password"}
                </TextStyle>
              </WrapText>
            </Row>
            {/* <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Status</TitleStyle>
              </WrapText>
              <Wrap>
                {!isEdit ? (
                  <WrapText>
                    <TextStyle>{agent?.status?.toUpperCase()}</TextStyle>
                  </WrapText>
                ) : (
                  <>
                    <WrapText>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="selectedType"
                          value={selectedStatus}
                          onChange={handleChangeSelect}
                          size="small"
                        >
                          <MenuItem value={AgentStatus.Pending}>
                            Pending
                          </MenuItem>
                          <MenuItem value={AgentStatus.Approved}>
                            Approved
                          </MenuItem>
                          <MenuItem value={AgentStatus.Block}>Block</MenuItem>
                        </Select>
                      </FormControl>
                    </WrapText>
                  </>
                )}
              </Wrap>
            </Row> */}

            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Id Card</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.id_card ? agent?.id_card : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>Google Id</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.googleId ? agent?.googleId : "-"}</TextStyle>
              </WrapText>
            </Row>
            <Row marginLeft={50}>
              <WrapText>
                <TitleStyle>RefCode</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{agent?.ref_code}</TextStyle>
              </WrapText>
            </Row>
            {/* <Row marginLeft={50}>
              <WrapTextRightColumn>
                <TitleStyle>Submitted Registration</TitleStyle>
              </WrapTextRightColumn>
              <WrapText>
                <TextStyle>
                  {agent?.updated ? (
                    <Chip label="Yes" color="success" variant="outlined" />
                  ) : (
                    <Chip label="No" color="error" variant="outlined" />
                  )}
                </TextStyle>
              </WrapText>
            </Row> */}
            {/* <Row>
              <WrapText>
                <TitleStyle>Created Date</TitleStyle>
              </WrapText>
              <WrapText>
                <TextStyle>{user?.createdAt?.toString()}</TextStyle>
              </WrapText>
            </Row> */}
          </Column>
        </Row>
        {/* {["SUPPER"].includes(role) && (
          <Row style={{ justifyContent: "center" }}>
            {!isEdit ? (
              <CustomButton onClick={handleOpenEdit} bgColor="#E4A11B">
                Update
              </CustomButton>
            ) : (
              <>
                <WrapButton>
                  <CustomButton
                    loading={isLoading}
                    disabled={isLoading}
                    loadingPosition="start"
                    startIcon={<></>}
                    onClick={handleUpdateProfile}
                    bgColor="#14A44D"
                  >
                    Save
                  </CustomButton>
                  <CustomButton
                    onClick={handleCancelEdit}
                    bgColor="gray"
                    // width={50}
                  >
                    Cancel
                  </CustomButton>
                </WrapButton>
              </>
            )}
          </Row>
        )} */}
        <Row style={{ justifyContent: "center" }}>
          {!isEdit ? (
            <CustomButton onClick={handleOpenEdit} bgColor="#E4A11B">
              Update
            </CustomButton>
          ) : (
            <>
              <WrapButton>
                <CustomButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={handleUpdateProfile}
                  bgColor="#14A44D"
                >
                  Save
                </CustomButton>
                <CustomButton
                  onClick={handleCancelEdit}
                  bgColor="gray"
                  // width={50}
                >
                  Cancel
                </CustomButton>
              </WrapButton>
            </>
          )}
        </Row>
      </Wrapper>

      {imgPopup && (
        <CustomModal
          open={imgPopup ? true : false}
          onClose={() => setImgPopup(null)}
        >
          {/* <ModalContainer> */}
          <img src={imgPopup} alt="" />
          {/* </ModalContainer> */}
        </CustomModal>
      )}
    </Container>
  );
}

export default Profile;
