import styled from "styled-components";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import OrderContext from "../../context/order-context";
import useSummary from "../../hooks/use-summary";
import SummaryBlock from "./SummaryBlock";

interface ISummaryProps {
  totalOrder: number;
  totalIncome?: number;
  percent?: number;
}
export interface ISummary {
  All: ISummaryProps;
  InProgress: ISummaryProps;
  Confirmed: ISummaryProps;
  Packing: ISummaryProps;
  Shipping: ISummaryProps;
  RequestCancel: ISummaryProps;
  RejectRequestCancel: ISummaryProps;
  SupportRequestCancel: ISummaryProps;
  Canceled: ISummaryProps;
  Delivered: ISummaryProps;
  Refunded: ISummaryProps;
}
const Summary = () => {
  let [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "All";
  const { summariesData } = useSummary();
  const navigate = useNavigate();

  return (
    <>
      {summariesData &&
        Object.keys(summariesData).map((key, index) => {
          return (
            <>
              {summariesData[key as keyof ISummary].totalOrder ? (
                <SummaryBlock
                  key={index}
                  title={key}
                  isActive={currentTab === key}
                  onClick={() => {
                    navigate(`/order?tab=${key}`);
                  }}
                  percent={summariesData[key as keyof ISummary].percent}
                  totalOrder={summariesData[key as keyof ISummary].totalOrder}
                  totalIncome={summariesData[key as keyof ISummary].totalIncome}
                />
              ) : undefined}
            </>
          );
        })}
    </>
  );
};

export default Summary;
