import axios from "axios";
import { IMaterial, Login, UpdateOrder, User } from "../constants/interface";
import { UpdateUserProfile } from "../pages/user/components/detail/constants";
import { UpdateUserAgentProfile } from "../pages/user-agent/components/detail/constants";
import {
  AddMedia,
  UpdateMedia,
  DeleteMedia,
  SendEmail,
} from "../pages/media/constants";
import { AddEvent, UpdateEvent, DeleteEvent } from "../pages/event/constants";
import { AddPopup, UpdatePopup, DeletePopup } from "../pages/popup/constants";
import { Request } from "./axiosClient";
import { ISupportRequest } from "../pages/order/constants";
import { ProductKeyApplication } from "../pages/product-key/constants";
import { MaterialType } from "../constants/types";

const Api = {
  Auth: {
    login: async function (params: Login) {
      return await Request.post("/auth/admin/login", { data: params });
    },
  },
  Admin: {
    getUsers: async function () {
      return await Request.get("/admin/user?limit=0&&page=0");
    },
    getWithdrawHistories: async function () {
      return await Request.get("/admin/withdraw");
    },

    getProfile: async function () {
      return await Request.get("/admin/profile");
    },

    getUserFeedback: async function () {
      return await Request.get("/admin/user-feedback");
    },
    getFeedbackSummary: async function () {
      return await Request.get("/admin/user-feedback/summary");
    },

    getSettings: async function () {
      return await Request.get("/admin/application/settings");
    },
    getUserPointHistory: async function (userId: string) {
      return await Request.get(
        `admin/user-point-history?limit=0&page=0&userId=${userId}`
      );
    },
    updateSettings: async function (params: { key: string; value: string }) {
      return await Request.post("/admin/application/settings/update", {
        data: params,
      });
    },
    updateUserProfile: async function (params: UpdateUserProfile) {
      return await Request.post("/admin/user/update/profile", {
        data: params,
      });
    },

    approvePayout: async function (params: { withdrawId?: string }) {
      return await Request.post("/admin/withdraw/approve-pp-payout", {
        data: params,
      });
    },
    rejectPayout: async function (params: {
      withdrawId?: string;
      reject_reason?: string;
    }) {
      return await Request.post("/admin/withdraw/reject-payout", {
        data: params,
      });
    },
  },

  Agent: {
    getAgents: async function () {
      return await Request.get("/admin/user-agent?limit=0&&page=0");
    },
    getAgentsPending: async function () {
      return await Request.get("/admin/user-agent/pending?limit=0&&page=0");
    },
    approveProfile: async function (agentId: string) {
      return await Request.post(`/admin/user-agent/approve/${agentId}`);
    },
    approveIdentify: async function (agentId: string) {
      return await Request.post(
        `/admin/user-agent/approve-identify/${agentId}`
      );
    },
    rejectIdentify: async function (
      agentId: string,
      params: { reason: string }
    ) {
      return await Request.post(
        `/admin/user-agent/reject-identify/${agentId}`,
        {
          data: params,
        }
      );
    },
    cancelApproveProfile: async function (
      agentId: string,
      params: { reason: string }
    ) {
      return await Request.post(`/admin/user-agent/cancel-approve/${agentId}`, {
        data: params,
      });
    },
    cancelSubmitProfile: async function (
      agentId: string,
      params: { reason: string }
    ) {
      return await Request.post(`/admin/user-agent/cancel-submit/${agentId}`, {
        data: params,
      });
    },
    updateProfile: async function (params: UpdateUserAgentProfile) {
      return await Request.post("/admin/user-agent/update/profile", {
        data: params,
      });
    },
    getAgentStatusHistories: async function (agentId: string) {
      return await Request.get(`/admin/user-agent-status-history/${agentId}`);
    },
  },

  Media: {
    uploadImage: async function uploadImage(params: any) {
      console.log("params:", params);
      return await Request.post("/admin/blog/upload", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    getMedias: async function () {
      return await Request.get("/admin/blog");
    },
    addMedia: async function addTips(params: AddMedia) {
      return await Request.post("/admin/blog", {
        data: params,
      });
    },
    updateMedia: async function updateTips(params: UpdateMedia) {
      return await Request.post("/admin/blog/update", {
        data: params,
      });
    },
    deleteMedia: async function deleteTips(params: DeleteMedia) {
      return await Request.post("/admin/blog/delete", {
        data: params,
      });
    },
    sendEmail: async function sendEmail(params: SendEmail) {
      return await Request.post("/admin/blog/send-mail", {
        data: params,
      });
    },
  },
  SupportRequest: {
    getSupportRequestCancel: async function () {
      return await Request.get("/admin/order-cancel");
    },
    updateSupportRequest: async function (params: ISupportRequest) {
      return await Request.post("/admin/order-cancel/update", {
        data: params,
      });
    },
    approveSupportRequest: async function (params: {
      supportRequestId: string;
    }) {
      return await Request.post("/admin/order-cancel/refunded", {
        data: params,
      });
    },
  },

  Order: {
    getOrders: async function () {
      return await Request.get("/admin/order");
    },

    getSummaryOrders: async function () {
      return await Request.get("/admin/order/summary");
    },
    getTrackingOrder: async function (orderId: string) {
      return await Request.get(
        `/admin/order/tracking-status?orderId=${orderId}`
      );
    },
    getTrackingOrderV2: async function (orderId: string) {
      return await Request.get(`/admin/order/tracking?orderId=${orderId}`);
    },
    getTrackingShipment: async function (orderId: string) {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN_API}/admin/order/tracking?orderId=${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("x-access-token")}`,
          },
        }
      );
      return response;
    },
    updateOrder: async function (params: UpdateOrder) {
      return await Request.post("/admin/order/update", {
        data: params,
      });
    },
    fakeOrderIsShipping: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/order-is-shipping", {
        data: params,
      });
    },
    fakeOrderHasArrived: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/order-has-arrived", {
        data: params,
      });
    },
    fakeOrderIsShipped: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/order-is-shipped", {
        data: params,
      });
    },
    inProgress: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/in-progress", {
        data: params,
      });
    },
    confirm: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/confirm", {
        data: params,
      });
    },
    pack: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/pack", {
        data: params,
      });
    },
    ship: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/ship", {
        data: params,
      });
    },
    rejectCancel: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/reject-cancel", {
        data: params,
      });
    },
    approveCancel: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/approve-cancel", {
        data: params,
      });
    },
    refund: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/refund", {
        data: params,
      });
    },
    delivered: async function (params: { orderId: string }) {
      return await Request.post("/admin/order/delivered", {
        data: params,
      });
    },
  },

  Community: {
    getPosts: async function () {
      return await Request.get("/admin/newsfeed?limit=0&&page=0");
    },
    approvePost: async function (newsfeedId: string) {
      return await Request.post(`/admin/newsfeed/approve/${newsfeedId}`);
    },
    cancelApprovePost: async function (newsfeedId: string) {
      return await Request.post(`/admin/newsfeed/cancel-approve/${newsfeedId}`);
    },
    deletePost: async function (newsfeedId: string) {
      return await Request.post(`/admin/newsfeed/delete/${newsfeedId}`);
    },
    // cancelApproveProfile: async function (
    //   agentId: string,
    //   params: { reason: string }
    // ) {
    //   return await Request.post(`/admin/user-agent/cancel-approve/${agentId}`, {
    //     data: params,
    //   });
    // },
  },

  NewsfeedComment: {
    getComments: async function (newsfeedId: string) {
      return await Request.get(
        `/admin/newsfeed-comment/{newsfeedId}?limit=0&page=0&newsfeedId=${newsfeedId}`
      );
    },
    approveComment: async function (commentId: string) {
      return await Request.post(`/admin/newsfeed-comment/approve/${commentId}`);
    },
    cancelApproveComment: async function (commentId: string) {
      return await Request.post(
        `/admin/newsfeed-comment/cancel-approve/${commentId}`
      );
    },
    deleteComment: async function (commentId: string) {
      return await Request.post(`/admin/newsfeed-comment/delete/${commentId}`);
    },
  },
  Address: {
    getDistricts: async function ({
      country_id,
      state_id,
    }: {
      country_id: number;
      state_id: number;
    }) {
      return await Request.get(
        `/city?country_id=${country_id}&state_id=${state_id}`
      );
    },
    getCities: async function ({
      country_id,
    }: {
      country_id: number | string;
    }) {
      return await Request.get(`/state?country_id=${country_id}`);
    },
    getCountries: async function () {
      return await Request.get(`/country`);
    },
  },
  Subscribers: {
    getSubscribers: async function () {
      return await Request.get(`/admin/subscribers`);
    },
  },
  Event: {
    uploadImage: async function uploadImage(params: any) {
      return await Request.post("/admin/user-agent-notification/upload", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    getEvents: async function () {
      return await Request.get("/admin/user-agent-notification/event");
    },
    addEvent: async function addEvent(params: AddEvent) {
      return await Request.post("/admin/user-agent-notification/event", {
        data: params,
      });
    },
    updateEvent: async function updateEvent(params: UpdateEvent) {
      return await Request.post("/admin/user-agent-notification/event/update", {
        data: params,
      });
    },
    deleteEvent: async function deleteEvent(params: DeleteEvent) {
      return await Request.post("/admin/user-agent-notification/event/delete", {
        data: params,
      });
    },
  },
  Popup: {
    uploadImage: async function uploadImage(params: any) {
      return await Request.post("/admin/popup/upload", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    getPopups: async function () {
      return await Request.get("/admin/popup");
    },
    addPopup: async function addPopup(params: AddPopup) {
      return await Request.post("/admin/popup", {
        data: params,
      });
    },
    updatePopup: async function updatePopup(params: UpdatePopup) {
      return await Request.post("/admin/popup/update", {
        data: params,
      });
    },
    deletePopup: async function deletePopup(params: DeletePopup) {
      return await Request.post("/admin/popup/delete", {
        data: params,
      });
    },
  },
  ProductKey: {
    getProductKeys: async function () {
      return await Request.get("/admin-product-key");
    },

    createProductKeys: async function (params: { quantity: number }) {
      return await Request.post("/admin-product-key", {
        data: params,
      });
    },

    activeProductKey: async function (params: {
      device_id: string;
      code: string;
      application: ProductKeyApplication;
    }) {
      return await Request.post("/product-key/active", {
        data: params,
      });
    },

    deactivateProductKey: async function (params: {
      code: string;
      device_id: string;
      application: ProductKeyApplication;
    }) {
      return await Request.post("/admin-product-key/deactivate", {
        data: params,
      });
    },

    reactiveProductKey: async function (params: {
      code: string;
      device_id: string;
      application: ProductKeyApplication;
    }) {
      return await Request.post("/admin-product-key/reactive", {
        data: params,
      });
    },
  },
  MaterialsLib: {
    getMaterials: async function (params: { type: MaterialType }) {
      return await Request.get(`/admin-materials-library?type=${params.type}`);
    },

    uploadImage: async function (params: FormData) {
      return await Request.post("/admin-materials-library/upload/image", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    uploadVideo: async function (params: FormData) {
      return await Request.post("/admin-materials-library/upload/video", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    uploadOther: async function (params: FormData) {
      return await Request.post("/admin-materials-library/upload/file", {
        data: params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    createMaterial: async function (params: IMaterial) {
      return await Request.post("/admin-materials-library/create", {
        data: params,
      });
    },

    updateMaterial: async function (
      params: IMaterial & { material_id: string }
    ) {
      return await Request.post("/admin-materials-library/update", {
        data: params,
      });
    },

    deleteMaterial: async function (params: { material_id: string }) {
      return await Request.post("/admin-materials-library/delete", {
        data: params,
      });
    },
  },
};

export default Api;
