import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { Subscriber } from "../../../constants/interface";
import { UserType_Subscriber } from "../../../constants/types";

import { convertDateToYYYYMMDD_HHmm } from "../../../utils";
import Api from "../../../service";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Subscribers = () => {
  const gridRef = useRef<AgGridReact<Subscriber>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<Subscriber[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: "user_email",
      headerName: "Email",
      cellStyle: { "font-weight": "600" },
    },
    {
      field: "userType",
      headerName: "Subscribe Site",
      cellStyle: { "font-weight": "600" },
    },
    {
      field: "createdAt",
      headerName: "Date",
      filter: false,
      floatingFilter: false,
      cellStyle: { "font-weight": "600" },
    },
    // {
    //   colId: "action",
    //   headerName: "",
    //   floatingFilter: false,
    //   cellRenderer: ActionsCell,
    //   filter: false,
    // },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    Api.Subscribers.getSubscribers().then((subscribers: any) => {
      console.log("listSubscribers:", subscribers);
      const formattedDateLisSubscriber = subscribers?.data?.map(
        (subscriber: Subscriber) => {
          return {
            ...subscriber,
            userType:
              subscriber.type === UserType_Subscriber.User
                ? "PRESALE"
                : subscriber.type,
            createdAt: `${convertDateToYYYYMMDD_HHmm(subscriber.createdAt)}`,
          };
        }
      );
      setRowData(formattedDateLisSubscriber);
      gridRef?.current?.api.sizeColumnsToFit({
        defaultMinWidth: 50,
        columnLimits: [
          // { key: "type", maxWidth: 150 },
          { key: "createdAt", maxWidth: 150 },
        ],
      });
    });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  useEffect(() => {
    onGridReady();
  }, []);

  return (
    <Container>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<Subscriber>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
    </Container>
  );
};
export default Subscribers;
