import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { Agent } from "../../../constants/interface";
import Detail from "./detail/Detail";

import { convertDateToYYYYMMDD_HHmm } from "../../../utils";
import Api from "../../../service";
import { Modal } from "@mui/material";
import UserContext from "../context/agent-context";
import LoginTypeCell from "./LoginTypeCell";
import ActionsCell from "./ActionsCell";
import StatusField from "./StatusField";
import Submitted from "./Submitted";
import {
  ExportExcel,
  styleDataOfColumn,
} from "../../../components/export/Excel";
import moment from "moment";
import { ICellRendererParams } from "ag-grid-community";
import { convertTimeIntToDate } from "./cellRender/TimeIntToDate";
import { filterParams } from "./cellRender/FilterDate";
import ExportExcelV2 from "../../../components/export/ExcelV2";
import { handleExportToExcel } from "../../../components/export/exportExcel";
import IdentifyStatus from "./IdentifyStatus";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;
const PaginationContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
const NumberOfDaoWithSameLevelContainer = styled.div`
  margin-top: 50px;
  position: absolute;
  right: 0;
  label {
    font-size: 18px;
    font-style: italic;
    span {
      font-weight: 700;
    }
  }
`;
const ModalContainer = styled.div`
  width: 80%;
  height: 90vh;
  max-height: 90vh;
  overflow-y: hidden;
`;

const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ListAgent = () => {
  const userCtx = useContext(UserContext);
  const { isOpenDetailModal, setIsOpenDetailModal } = userCtx;

  const gridRef = useRef<AgGridReact<Agent>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<Agent[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    // {
    //   field: "avatar",
    //   cellRenderer: Avatar,
    //   filter: false,
    //   floatingFilter: false,
    // },
    { field: "fullname", headerName: "Full name" },
    {
      field: "provider",
      headerName: "Login Type",
      cellRenderer: LoginTypeCell,
    },
    {
      field: "email",
      headerName: "Email",
      cellStyle: { fontWeight: "700" },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: StatusField,
    },
    {
      field: "updated",
      headerName: "Submitted Registration",
      cellRenderer: Submitted,
      minWidth: 180,
    },
    {
      field: "identifyStatus",
      headerName: "Submitted Identify",
      cellRenderer: IdentifyStatus,
      minWidth: 180,
    },
    {
      field: "country",
      headerName: "Country",
    },
    {
      field: "level",
      headerName: "Level",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      filter: false,
    },
    {
      field: "approvedAt",
      headerName: "Approved At",
      // editable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      cellRenderer: (props: ICellRendererParams) => {
        const time = props.data.approvedAt;
        return convertTimeIntToDate(time);
      },
      minWidth: 180,
    },
    {
      field: "cancelApprovedAt",
      headerName: "Canceled At",
      // editable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      cellRenderer: (props: ICellRendererParams) => {
        const time = props.data.cancelApprovedAt;
        return convertTimeIntToDate(time);
      },
      minWidth: 180,
    },

    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
      resizable: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const headersExcel = [
    [
      ["Full Name"],
      ["Email"],
      ["Country"],
      ["Phone"],
      ["Status"],
      ["Submitted Registration"],
      ["Address Detail"],
      ["Dob"],
      ["Gender"],
      ["Selfie ID Front"],
      ["ID Front"],
      ["ID Back"],
      ["Main Channel"],
      ["Currently Job"],
      ["Area Of Business"],
      ["Sale Experience"],
      ["Expecting Sale Volume"],
      ["Why Choose Start24"],
      ["Most Preferable Features"],
      ["Difficulties"],
      ["Verified"],
      ["Provider"],
      ["Login Type"],
      ["Id Card"],
      ["Google Id"],
      ["RefCode"],
      ["Created At"],
      ["Updated At"],
    ],
  ];

  const styleDataOfColumns: styleDataOfColumn[] = [
    {
      column: "M",
      style: {
        alignment: {
          wrapText: true,
        },
      },
    },
    {
      column: "N",
      style: {
        alignment: {
          wrapText: true,
        },
      },
    },
  ];

  const formatExcelData = (listAgent: Agent[]) => {
    const customData = listAgent?.map((item) => {
      return {
        fullname: item.fullname,
        email: item.email,
        country: item.country,
        phone: item.phone?.toString(),
        status: item.status?.toUpperCase(),
        submitted: item.updated ? "YES" : "NO",
        addressDetail: item.address_detail,
        dob: item.dob && moment(item.dob).format("YYYY-MMM-DD"),
        gender: item.gender,
        image_selfie: item.image_selfie,
        image_card_identify_front: item.image_card_identify_front,
        image_card_identify_back: item.image_card_identify_back,
        main_channel:
          Array.isArray(item?.main_channel) &&
          item?.main_channel?.find((channel) => channel.channel)
            ? item?.main_channel
                ?.map((channel) => `${channel.channel} : ${channel.link}`)
                .join("\n")
            : "",
        currently_job: item.currently_job,
        areas_of_bussiness: item.areas_of_bussiness,
        sale_experience: item.sale_experience,
        sales_volume: item.sales_volume,
        outline_reason: item.outline_reason,
        as_an_agent: item.as_an_agent,
        what_do_you_think: item.what_do_you_think,
        verified: item.verified ? "True" : "False",
        provider: item.provider,
        loginType: item.googleId ? "Google" : "Email/Password",
        id_card: item.id_card,
        googleId: item.googleId?.toString(),
        ref_code: item.ref_code,
        createdAt: convertDateToYYYYMMDD_HHmm(item.createdAt),
        updatedAt: convertDateToYYYYMMDD_HHmm(item.updatedAt),
      };
    });
    return customData;
  };

  const onGridReady = useCallback(() => {
    Api.Agent.getAgents().then((listAgents: any) => {
      console.log("listAgents:", listAgents);
      const formattedDateListUser = listAgents.data.data.map((user: Agent) => {
        return {
          ...user,
          status: user.status?.toUpperCase(),
          createdAt: `${convertDateToYYYYMMDD_HHmm(user.createdAt)}`,
        };
      });
      setRowData(formattedDateListUser);
      gridRef?.current?.api.sizeColumnsToFit({
        defaultMinWidth: 50,
        columnLimits: [
          { key: "avatar", maxWidth: 100 },
          { key: "fullname", minWidth: 100 },
          { key: "loginType", minWidth: 100 },
        ],
      });
    });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  // const onRowDoubleClicked = (params: any) => {
  //   const data = params.data;
  //   console.log("params:", data);
  //   setAgent(data);
  //   setIsOpenDetailModal(true);
  // };
  const exportToExcelHandler = () => {
    const dataToExport: any[] = [];
    gridRef.current!.api.forEachNodeAfterFilterAndSort((node) => {
      dataToExport.push(node.data);
    });
    const formattedDataToExport = formatExcelData(dataToExport);
    handleExportToExcel({
      headers: headersExcel,
      data: formattedDataToExport,
      fileName: "User-Agent-List.xlsx",
      styleDataOfColumns,
    });
  };

  useEffect(() => {
    onGridReady();
  }, []);

  return (
    <Container>
      <ExportExcelV2 onClick={exportToExcelHandler} />

      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<Agent>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
      {isOpenDetailModal && (
        <CustomModal
          open={isOpenDetailModal}
          onClose={() => setIsOpenDetailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <Detail />
            {/* <Profile /> */}
          </ModalContainer>
        </CustomModal>
      )}
    </Container>
  );
};
export default ListAgent;
