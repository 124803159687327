import { Button, Tooltip } from "@mui/material";
import { HTMLProps, useMemo } from "react";
import styled from "styled-components";
import { Icon } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BoxIcon from "../../icons/Box";
import BoxIcon2 from "../../icons/Box2";
import BoxIcon3 from "../../icons/Box3";
import InProgressIcon from "../../icons/InProgress";
import ShipIcon from "../../icons/Ship";
import RejectIcon from "../../icons/Reject";
import ApproveIcon from "../../icons/Approve";
import RefundIcon from "../../icons/Refund";
import DeliveredIcon from "../../icons/Delivered";
import ConfirmIcon from "../../icons/Confirm";
import React from "react";

const Container = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CustomButton = styled(Button)<{
  $bgColor: string;
  width?: number;
}>`
  display: inline-block !important;
  vertical-align: middle !important;
  /* padding: 0 !important; */
  padding: 0 1rem !important;
  margin: 0 !important;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  min-width: 35px !important;
  height: 35px !important;
  line-height: unset !important;
  background-color: ${({ $bgColor }) =>
    $bgColor ? `${$bgColor} !important` : "#54B4D3 !important"};
  color: #fff !important;

  .MuiIcon-root {
    height: auto;
    vertical-align: middle;
  }
  /* :hover + title {
    display: block !important;
  } */
  /* :hover::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 110%;
    height: 2rem;
    width: auto;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #595757 !important;
    z-index: 99999999999999999999999999999999999999999;
  } */
`;

const IconWrap = styled(Icon)`
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

const AddIcon = styled(AddCircleOutlineOutlinedIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const UpdateIcon = styled(EditNoteOutlinedIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const ViewIcon = styled(VisibilityOutlinedIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const DeleteIcon = styled(DeleteOutlineOutlinedIcon)`
  width: 100% !important;
  height: 100% !important;
`;

const EmailIcon = styled(MailOutlineIcon)`
  width: 100% !important;
  height: 100% !important;
`;

export enum CRUDType {
  Add = "ADD",
  Update = "UPDATE",
  Delete = "DELETE",
  View = "VIEW",
  InProgress = "IN PROGRESS",
  Confirm = "CONFIRM",
  Pack = "PACK",
  Ship = "SHIP",
  Reject = "REJECT",
  Approve = "APPROVE",
  Refund = "REFUND",
  Delivered = "DELIVERED",
  Email = "EMAIL",
  Response = "Response",
  Refunded = "REFUNDED",
  Deactivate = "DEACTIVATE",
  Reactive = "REACTIVE",
}

export enum ButtonDisplayType {
  Icon = "ICON",
  Text = "TEXT",
}

type ButtonProps = {
  type: string | undefined;
  size: "large" | "medium" | "small";
  width?: number;
  disabled?: boolean;
  displayType?: ButtonDisplayType;
  onClick?: () => void;
};

function ButtonCRUD({
  type,
  size,
  width,
  disabled,
  displayType,
  onClick,
}: ButtonProps) {
  const buttonElement = useMemo(() => {
    switch (type) {
      case CRUDType.Response:
        return (
          <CustomButton $bgColor="#bbb801" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <EmailIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Response}</span>
            )}
          </CustomButton>
        );
      case CRUDType.Email:
        return (
          <CustomButton $bgColor="#14A44D" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <EmailIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Email}</span>
            )}
          </CustomButton>
        );
      case CRUDType.Delivered:
        return (
          // <Tooltip title="Delivered" arrow placement="right">
          <CustomButton $bgColor="#40d34c" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <DeliveredIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Delivered}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Refund:
        return (
          // <Tooltip title="Refund" arrow placement="right">
          <CustomButton $bgColor="#40d84d" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <RefundIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Refund}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Approve:
        return (
          // <Tooltip title="Approve Cancel" arrow placement="right">
          <CustomButton $bgColor="#4ce096" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ApproveIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Approve}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Refunded:
        return (
          // <Tooltip title="Approve Cancel" arrow placement="right">
          <CustomButton $bgColor="#4ce096" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ApproveIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Refunded}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Reject:
        return (
          // <Tooltip title="Reject Cancel" arrow placement="right">
          <CustomButton $bgColor="#db2d2d" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <RejectIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Reject}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Ship:
        return (
          // <Tooltip title="Ship" arrow placement="right">
          <CustomButton $bgColor="#ea6767" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ShipIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Ship}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );

      case CRUDType.Pack:
        return (
          // <Tooltip title="Pack" arrow placement="right">
          <CustomButton $bgColor="#b5bc78" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <BoxIcon3 />
              </IconWrap>
            ) : (
              <span>{CRUDType.Pack}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Confirm:
        return (
          // <Tooltip title="Confirm" arrow placement="right">
          <CustomButton $bgColor="#4ce056" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ConfirmIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Confirm}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Reactive:
        return (
          // <Tooltip title="Confirm" arrow placement="right">
          <CustomButton $bgColor="#4ce056" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ConfirmIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Reactive}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.InProgress:
        return (
          // <Tooltip title="In Progress" arrow placement="right">
          <CustomButton $bgColor="#4ce096" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <InProgressIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.InProgress}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );
      case CRUDType.Add:
        return (
          <CustomButton $bgColor="#14A44D" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <AddIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Add}</span>
            )}
          </CustomButton>
        );

      case CRUDType.View:
        return (
          // <Tooltip title="View" arrow placement="right">
          <CustomButton $bgColor="#54B4D3" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ViewIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.View}</span>
            )}
          </CustomButton>
          // </Tooltip>
        );

      case CRUDType.Update:
        return (
          <CustomButton $bgColor="#E4A11B" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <UpdateIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Update}</span>
            )}
          </CustomButton>
        );
      case CRUDType.Delete:
        return (
          <CustomButton $bgColor="#DC4C64" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <DeleteIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Delete}</span>
            )}
          </CustomButton>
        );
      case CRUDType.Deactivate:
        return (
          <CustomButton $bgColor="#DC4C64" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <DeleteIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.Deactivate}</span>
            )}
          </CustomButton>
        );

      default:
        return (
          <CustomButton $bgColor="#54B4D3" width={width} disabled={disabled}>
            {displayType === ButtonDisplayType.Icon ? (
              <IconWrap fontSize={size}>
                <ViewIcon />
              </IconWrap>
            ) : (
              <span>{CRUDType.View}</span>
            )}
          </CustomButton>
        );
    }
  }, []);

  return <Container onClick={onClick}>{buttonElement}</Container>;
}

export default React.memo(ButtonCRUD);
