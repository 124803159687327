export const NavbarList = [
  {
    id: "1",
    title: "User",
    url: "/user",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "2",
    title: "User Agent",
    url: "",
    isActive: false,
    isExpand: false,
    subMenu: [
      {
        id: "1",
        title: "Account Agent",
        url: "/user-agent/account-agent",
        isActive: false,
        isExpand: false,
      },
      {
        id: "2",
        title: "Pending Requests",
        url: "/user-agent/pending-requests",
        isActive: false,
        isExpand: false,
      },
    ],
  },
  {
    id: "3",
    title: "Media",
    url: "/media",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "4",
    title: "Order",
    url: "/order",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "5",
    title: "Community",
    url: "/community",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "6",
    title: "Withdraw",
    url: "/withdraw",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "7",
    title: "Subscribers",
    url: "/subscribers",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "8",
    title: "Agent Event",
    url: "/agent-event",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  {
    id: "9",
    title: "Popup",
    url: "/popup",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
  // coming soon
  // {
  //   id: "10",
  //   title: "Product Key",
  //   url: "/product-key",
  //   isActive: false,
  //   isExpand: false,
  //   subMenu: [],
  // },
  // {
  //   id: "11",
  //   title: "Materials",
  //   url: "",
  //   isActive: false,
  //   isExpand: false,
  //   subMenu: [
  //     {
  //       id: "1",
  //       title: "Images",
  //       url: "/materials-lib/images",
  //       isActive: false,
  //       isExpand: false,
  //     },
  //     {
  //       id: "2",
  //       title: "Videos",
  //       url: "/materials-lib/videos",
  //       isActive: false,
  //       isExpand: false,
  //     },
  //     {
  //       id: "3",
  //       title: "Others",
  //       url: "/materials-lib/others",
  //       isActive: false,
  //       isExpand: false,
  //     },
  //   ],
  // },

  {
    id: "12",
    title: "Setting",
    url: "/setting",
    isActive: false,
    isExpand: false,
    subMenu: [],
  },
];

// {
//   id: "7",
//   title: "Tips",
//   url: "",
//   isActive: false,
//   isExpand: false,
//   subMenu: [
//     {
//       id: "1",
//       title: "Tips",
//       url: "/tips",
//       isActive: false,
//       isExpand: false,
//     },
//     {
//       id: "2",
//       title: "Category",
//       url: "/tip-category",
//       isActive: false,
//       isExpand: false,
//     },
//     {
//       id: "3",
//       title: "Hashtag",
//       url: "/tip-hashtag",
//       isActive: false,
//       isExpand: false,
//     },
//     {
//       id: "4",
//       title: "Creator",
//       url: "/tip-creator",
//       isActive: false,
//       isExpand: false,
//     },
//   ],
// },
