import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  PaginationNumberFormatterParams,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import styled from "styled-components";
import { NewsfeedComment } from "../../../../../constants/interface";
// import Detail from "./detail/Detail";

import { convertDateToYYYYMMDD_HHmm } from "../../../../../utils";
import Api from "../../../../../service";
import { Modal } from "@mui/material";
import CommunityContext from "../../../context/community-context";
import CommentContext from "./comment-context";
import { CellClickedEvent } from "ag-grid-community";
import ActionsCell from "./ActionsCell";
import StatusField from "../../StatusField";

const Container = styled.div`
  width: 100%;
  height: 55vh;
  /* box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  position: relative;
  .ag-row {
    cursor: pointer !important;
  }
`;

const PageSizeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -50px;
`;

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Comments = () => {
  const communityCtx = useContext(CommunityContext);
  const { post } = communityCtx;

  const commentCtx = useContext(CommentContext);
  const { isReloadList } = commentCtx;

  const gridRef = useRef<AgGridReact<NewsfeedComment>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<NewsfeedComment[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      field: "userName",
      headerName: "User Name",
      cellStyle: { "font-weight": "600" },
    },
    {
      field: "comment",
      headerName: "Comment",
      cellStyle: { "font-weight": "600" },
    },
    // {
    //   field: "like_count",
    //   headerName: "Like Count",
    //   cellStyle: { "font-weight": "600" },
    // },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: StatusField,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      filter: false,
    },
    {
      colId: "action",
      headerName: "",
      floatingFilter: false,
      cellRenderer: ActionsCell,
      filter: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // editable: true,
      // enableRowGroup: true,

      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      // flex: 1,
      minWidth: 100,
    };
  }, []);
  const paginationNumberFormatter = useCallback(
    (params: PaginationNumberFormatterParams) => {
      return "[" + params.value.toLocaleString() + "]";
    },
    []
  );

  const onGridReady = useCallback(() => {
    Api.NewsfeedComment.getComments(post?._id || "").then((comments: any) => {
      console.log("listcomments:", comments);
      const formattedDateListComment = comments?.data?.data?.map(
        (comment: NewsfeedComment) => {
          return {
            ...comment,
            userName: comment?.user?.fullname,
            status: comment.status?.toUpperCase(),
            createdAt: `${convertDateToYYYYMMDD_HHmm(comment.createdAt)}`,
          };
        }
      );
      setRowData(formattedDateListComment);
      gridRef?.current?.api.sizeColumnsToFit({
        defaultMinWidth: 50,
        columnLimits: [
          { key: "userName", maxWidth: 150 },
          { key: "like_count", maxWidth: 150 },
          { key: "status", maxWidth: 150 },
          { key: "createdAt", maxWidth: 150 },
          { key: "action", maxWidth: 250 },
        ],
      });
    });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    gridRef.current!.api.paginationGoToPage(0);
    gridRef.current!.api.paginationSetPageSize(20);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridRef.current!.api.paginationSetPageSize(Number(value));
  }, []);

  // const onRowDoubleClicked = (params: any) => {
  //   const data = params.data;
  //   console.log("params:", data);
  //   setAgent(data);
  //   setIsOpenDetailModal(true);
  // };

  useEffect(() => {
    onGridReady();
  }, [isReloadList]);

  return (
    <Container>
      <div style={containerStyle}>
        <div
          className="example-wrapper"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<NewsfeedComment>
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pivotPanelShow={"always"}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <PageSizeContainer className="example-header">
            Page Size:{" "}
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </PageSizeContainer>
        </div>
      </div>
    </Container>
  );
};
export default Comments;
