import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import PointHistory from "./pointHistory/PointHistory";
import Profile from "./profile/Profile";
import UserContext from "../../context/user-context";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 820px;
  background-color: #fff;
  padding: 20px;
`;
const Header = styled.div`
  padding: 8px 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const AvatarWrap = styled.div`
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 80px;
  border-radius: 50%;
`;

const TextStyle = styled.span`
  font-size: 22px;
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  text-transform: capitalize;
  color: #222222;
`;

const TitleStyle = styled(TextStyle)`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: gray;
`;

const FullName = styled(TitleStyle)`
  font-size: 26px;
  font-weight: 700;
  color: #222222;
`;

const Email = styled(TextStyle)`
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  color: #0b7bf4;
  text-decoration: underline;
`;

export const Type = styled.div`
  padding: 4px 8px;
  background: rgba(14, 203, 129, 0.1);
  border-radius: 3px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Dot = styled.span`
  background: #0ecb81;
  border-radius: 50%;
  min-width: 8px;
  width: 8px;
  height: 8px;
`;

export const TypeText = styled.span`
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0ecb81;
  text-transform: capitalize;
`;

const CustomTabContext = styled(TabContext)`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const CustomTabPanel = styled(TabPanel)<{ height?: string }>`
  width: 100%;
  background-color: #fff;
  padding: 24px 0 !important;
  ${({ height }) => height && { height: height }}
`;

const CustomTab = styled(Tab)`
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Quicksand" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  color: #222222 !important;
`;

function Detail() {
  const userCtx = useContext(UserContext);
  const { node, user, setUser } = userCtx;
  console.log("user: ", user);

  const [tab, setTab] = useState(1);
  const handleChange = (e: any, value: number) => {
    setTab(value);
  };
  return (
    <Container>
      <Header>
        <Column>
          <Row>
            <AvatarWrap>
              <Avatar src={user?.avatar} />
            </AvatarWrap>
            <Column>
              <FullName>{user?.fullname}</FullName>
              {/* <Type>
                <TypeText>{user?.type?.toLowerCase()}</TypeText>
              </Type> */}
            </Column>
          </Row>
          <Row>
            <Email>{user?.email}</Email>
          </Row>
        </Column>
      </Header>
      <CustomTabContext value={tab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <CustomTab label="Profile" value="1" />
            {/* <CustomTab label="Point History" value="2" /> */}
          </TabList>
        </Box>
        <CustomTabPanel height="calc(100% - 150px)" value="1">
          <Profile />
        </CustomTabPanel>
        <CustomTabPanel value="2">
          <PointHistory />
        </CustomTabPanel>
      </CustomTabContext>
    </Container>
  );
}

export default Detail;
